import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { FullScreenModal } from "./Enter/FullScreenModal";
import RootHeader from "./Header/RootHeader";

export default function Root({ children }) {
  return (
    <Back>
      <FullScreenModal />
      <RootHeader />
      <Lay>{children ? children : <Outlet />}</Lay>
    </Back>
  );
}

const Lay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
  min-height: 90vh;
`;

const Back = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;
