import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root";
import Enter from "./screens/Enter";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [{ path: "", element: <Enter /> }],
  },
]);

export default router;
