import React from "react";
import styled from "styled-components";
import media from "../../libs/media";
import { EmailButton } from "../Common";

export function FourSlider() {
  return (
    <FirstSliderLayout>
      <FirstLayout>
        <IFrame
          src={`https://www.youtube.com/embed/dcV9K1Ytk1w?si=iHgS5oYBRxYg-IbK?controls=0?light=1`}
          allow="accelerometer; picture-in-picture"
        ></IFrame>
        <DescSection>
          <h1>About Us</h1>
          <span>
            여기 그룹은 녹색건출물 조성 지원법에 따라 등록된 그린리모델링
            전문기업 입니다. 30년 이상의 설계, 시공 경험의 베테랑 건축사와
            시공기술자 들로 구성되어 있으며 1000여 건 이상의 대규모 건축물을
            설계, 시공 하였습니다. 2021년부터 설계 정확성과 고객 만족도를 높이고
            동시에 시공기간까지 단축하기 위해서 수주한 모든 모든 설계, 시공,
            감리 업무에 BIM(Building Information Modeling) 기술을 적용하고
            있습니다.
          </span>
        </DescSection>
      </FirstLayout>
    </FirstSliderLayout>
  );
}

const VideoSection = styled.div`
  width: 50%;
  height: 80%;
  min-height: 400px;
  background-color: gray;
`;
const DescSection = styled.div`
  color: #707070;
  width: 30%;

  height: 80%;
  padding: 4rem;
  font: normal normal normal 16px/24px Nanum Gothic;
  background-color: #bebebe;
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;

  h1 {
    padding: 1rem 0.2rem;
    font-size: 30px;
    font-weight: 700;
  }

  ${media.phone} {
    width: 100%;
    padding: 0;
  }
`;

const SliderLayout = styled.div`
  width: 90% !important;
  height: 100%;
  min-height: 60vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FirstSliderLayout = styled(SliderLayout)`
  max-width: 1600px;
`;

const FirstLayout = styled.div`
  margin-top: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 60%;
  }

  ${media.phone} {
    flex-direction: column;
    margin-top: 1rem;
    img {
      width: 100%;
    }
  }
`;

const IFrame = styled.iframe`
  width: 60%;
  margin-top: 1rem;
  min-height: 500px;

  .ytp-ytp-gradient-top {
    display: none;
  }

  ${media.phone} {
    width: 100%;
    min-height: 200px;
  }
`;
