import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import { BsFullscreenExit } from "react-icons/bs";
import { useRecoilState } from "recoil";
import {
  FullNumber,
  isFull,
  PrevSecondPlay,
  SecondPlay,
} from "../../libs/atoms";
import media from "../../libs/media";

export function FullScreenModal() {
  const [full, setFull] = useRecoilState(isFull);
  const [fullNum, setFullNum] = useRecoilState(FullNumber);
  const [onPlay, setPlay] = useRecoilState(SecondPlay);
  const [onPrevPlay, setPrevPlay] = useRecoilState(PrevSecondPlay);
  const onFull = () => {
    onPrevPlay ? setPlay(true) : setPlay(false);
    setFull((p) => !p);
  };

  return (
    <FullScreenModalLayout full={full} fullnum={fullNum}>
      <FullImg full={full} fullnum={fullNum} />
      <BsFullscreenExit onClick={onFull} />
    </FullScreenModalLayout>
  );
}

const FullScreenModalLayout = styled.div`
  display: ${(prop) => (prop.full ? "flex" : "none")};
  width: 100%;
  height: calc(100%);
  position: absolute;
  background-color: black;

  z-index: 1000;
  color: white;
  svg {
    font-size: 4rem;
    margin: 0.5rem;
    position: absolute;
    bottom: 2%;
    right: 1%;
    z-index: 1001;
  }
  ${media.phone} {
    align-items: center;
    justify-content: center;
    height: calc(200%);
  }
`;

const FullImg = styled.div`
  width: 100%;
  height: 100vh;
  background-image: ${(prop) =>
    `url("/images/secondslider/${prop.fullnum + 1}_full.jpg")`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 1000;
`;
