import React from "react";
import styled from "styled-components";
import media from "../../libs/media";
import { EmailButton } from "../Common";

export function FirstSlider() {
  const goToNotion = () => {
    window.open(
      "https://nebula-stag-04c.notion.site/HERE-NOW-eca2ec75a45b40e8bd7b66a2d3564153"
    );
  };

  return (
    <FirstSliderLayout>
      <FirstLayout>
        <IFrame
          src={`https://www.youtube.com/embed/0eObbGbmFBk?si=wrJm6gao1s0CBZul?controls=0?light=1`}
          allow="accelerometer; picture-in-picture"
        ></IFrame>
        <DescSection>
          <h1>MetaVerse</h1>

          <span>가상현실 내집 마련 프로젝트!</span>
          <SliderLogo
            src="/images/fslider_logo.png"
            width="100px"
            height="32px"
          />
          <span>
            여기지금(Here & NOW)
            <br /> "모든 사람들이 상상해온 나만의 집을 위해, 여기지금(Here &
            NOW)은 '메타시티'를 먼저 제안합니다. 메타시티를 통해 우리들은
            가상현실 속에서 내 집을 직접 지어 본 뒤에 현실세계로 옮길 수
            있습니다. 메타버스를 통해 꿈꾸던 집을 지금 바로 경험하세요!"
          </span>

          <EmailButton />
          <MetaBtn onClick={goToNotion}></MetaBtn>
        </DescSection>
      </FirstLayout>
    </FirstSliderLayout>
  );
}

const MetaBtn = styled.div`
  width: 200px;
  height: 90px;
  cursor: pointer;
  background-image: url("images/meta_btn.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0.5rem 0.6rem;
  margin-top: 1rem;
`;

const SliderLogo = styled.img`
  margin: 1rem 0px;
`;

const DescSection = styled.div`
  color: #707070;
  width: 30%;

  height: 80%;
  padding: 4rem;
  font: normal normal normal 16px/24px Nanum Gothic;
  background-color: #bebebe;
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;

  h1 {
    padding: 1rem 0.2rem;
    font-size: 30px;
    font-weight: 700;
  }
  ${media.phone} {
    padding: 0px;
    width: auto;
    span {
      margin-bottom: 1rem;
    }
  }
`;

const SliderLayout = styled.div`
  width: 90% !important;
  height: 100%;
  min-height: 60vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FirstSliderLayout = styled(SliderLayout)`
  max-width: 1600px;
`;

const FirstLayout = styled.div`
  margin-top: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.phone} {
    flex-direction: column;
    margin-top: 1rem;
  }
`;

const IFrame = styled.iframe`
  width: 60%;
  min-height: 500px;

  .ytp-ytp-gradient-top {
    display: none;
  }

  ${media.phone} {
    width: 100%;
    min-height: 200px;
  }
`;
