import React from "react";
import styled from "styled-components";
import media from "../../libs/media";
import { BsApple, BsWindows } from "react-icons/bs";
export function Release() {
  const WindowClick = () => {
    window.innerWidth > 500
      ? (window.location.href =
          "https://metaaxel.s3.us-west-1.amazonaws.com/mysetup.zip")
      : alert(
          "PC 환경에서 다운로드 가능 합니다. pc 환경이라면 창을 키워주세요."
        );
  };
  const MacosClick = () => {
    alert("준비중 입니다.");
  };
  return (
    <ReleaseSection>
      <ReleaseContentLay>
        <ReleaseContent>
          <ReleaseBtnDiv>
            <RBtn onClick={WindowClick}>
              <BsWindows style={{ color: "#0077DB", marginRight: "0.5rem" }} />
              윈도우
            </RBtn>
            <RBtn onClick={MacosClick}>
              <BsApple style={{ color: "#888888", marginRight: "0.5rem" }} />
              맥OS
            </RBtn>
            <span>메타버스 앱은 PC 버전에서 다운로드 받으실 수 있습니다.</span>
          </ReleaseBtnDiv>
          <span>다운로드 받아 설치하세요.</span>
          <span>GnY Group</span>
          <span>(주)여기건축사사무소</span>
          <span>(주)지앤와이아키텍텍</span>
          <span>서울특별시 서대문구 서소문로 37</span>
          <span>susutto@yugy.org</span>
          <span>사업자번호 : 827 - 86 - 02751</span>
        </ReleaseContent>
        <ReleaseNotice>
          <span>
            Release Note <br />
            2022.12. 20 / 메타버스 프로토타입 Beta 0.1 / 기본 메뉴, 1인칭
            플레이, 백련 경로당, 신사동 리모델링 공사, 제작의뢰 적용
            <br />
            2022.12. 27 / 메타버스 프로토타입 Beta 0.2 / 우정동, 중림동 리모델링
            공사, 메뉴 조작 및 소규모 인게임 에셋 업데이트 <br />
            2023. 1. 3 / 메타버스 프로토타입 Beta 0.3 / 메인 메뉴, 소규모 인게임
            에셋, 서버 데이터베이스 정보 적용 <br />
            2023. 1. 12 / 메타버스 프로토타입 Beta 0.3.1 / 메인메뉴, 모던시티,
            제이미 캐릭터, 환경 에셋, 질감, 쉐이더, 콜라이더, 조작 컨트롤 등
            업데이트 <br />
            2023. 1. 26 / 메타버스 프로토타입 Beta 0.3.2 / 스칼렛 캐릭터 추가,
            달리기/걷기 버튼 변경, 도로 및 광장 애셋 변경 <br />
            2023. 2. 8 / 메타버스 프로토타입 Beta 0.3.4 / 스팟별 메뉴 추가,
            버드뷰 추가, 컨트롤 메뉴 업데이트, BIM 건물 모델링 에셋 업데이트{" "}
            <br />
            2023. 2. 21 / 메타버스 프로토타입 Beta 0.4 / VFX효과 적용, 안개 효과
            삭제, 북가좌동 리모델링 공사, 아바타 선택 화면 추가 패치노트 <br />
            2023. 2. 27 / 메타버스 프로토타입 Beta 0.5 / 상호작용 시스템 추가,
            건물 정보 열어보기 추가 패치노트 <br /> 2023. 3. 8 / 메타버스
            프로토타입 Beta 0.5.1 / 남성 캐릭터 렛트 추가, 메타버스 씬 내에
            신사동 타운하우스 리모델링 추가 패치노트
            <br /> 2023. 4. 4 / 메타버스 프로토타입 Beta 0.5.2 / 메뉴 화면 변경,
            아현동 타운하우스 리모델링 공사
            <br /> 2023. 4. 10 / 메타버스 프로토타입 Beta 0.5.3 / 전시장 씬 내에
            NFT 그림 추가, 로그인 연동 방식 추가
            <br /> 2023. 4. 14 / 메타버스 프로토타입 Beta 0.5.4 / 메뉴화면 변경
            <br /> 2023. 4. 21 / 메타버스 프로토타입 Beta 0.6 / 전시장 씬 내에
            카페 건물 및 2층 건물, 상가 건물 추가, 우정동, 중림동, 신사동 건물
            전/후 미리보기 기능 추가 <br />
            2023. 4. 28 / 메타버스 프로토타입 Beta 0.6.1 / 신사동 씬에 건물정보
            패널 추가, 씬 입장 전 키입력 패널 추가, 메뉴 씬에서 여러 개의 버튼
            추가
            <br />
            2023. 5. 4 / 메타버스 프로토타입 Beta 0.6.2 / 버드뷰 활성 시 관심
            건물을 추적(일부 씬 제외), 기본 이동 속도 &lt;==&gt; 달리기 속도
            변환,실행 파일 아이콘 변경
          </span>
        </ReleaseNotice>
        <ReleaseContentFooter>
          <span>GnY Group / (주)여기건축사사무소 / (주)지앤와이아키텍</span>
          <span>
            서울특별시 서대문구 서소문로 37 / 사업자번호 : 827 - 86 - 02751
          </span>
        </ReleaseContentFooter>
      </ReleaseContentLay>
    </ReleaseSection>
  );
}

const RBtn = styled.div`
  min-width: 100px !important;
  width: 30% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.2rem 0.3rem;
  border-radius: 1rem;
  color: white;
  min-height: 30px;
  text-align: center;
  background-color: #52859c;
  margin: 0px 1rem;
  font-size: 0.8rem;
  cursor: pointer;
  span {
    margin: 0;
  }
  ${media.small} {
    width: 70%;
  }
`;

const ReleaseSection = styled.div`
  width: 100%;
  min-height: 20vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
  ${media.phone} {
    margin: 0;
  }
`;
const ReleaseContentLay = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  ${media.phone} {
    padding: 0;
  }
`;

const ReleaseContent = styled.div`
  display: flex;
  width: 100%;
  background-color: #f2f2f2;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  margin: 0px 1rem;
  span {
    font-size: 0.8rem;
    display: inline-block;
    padding: 0px 1rem;
    opacity: 0.5;
  }
  ${media.phone} {
    flex-direction: column;
    span {
      display: none;
    }
    padding: 0.5rem;
    margin: 0;
  }
`;

const ReleaseContentFooter = styled(ReleaseContent)`
  display: none;

  ${media.phone} {
    display: flex;
    span {
      display: block;
    }
    padding: 0.5rem;
    margin: 0;
  }
`;

const Btn = styled.button`
  width: 5%;
  min-width: 100px;
  border: none;
  margin: 0px 1rem;
  border-radius: 10px;
  min-height: 20px;
  background-position: center;
  color: white;
  padding: 0.2rem 1rem;
  cursor: pointer;
`;

const ReleaseNotice = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 0.8rem;
  line-height: 1.8;
  color: #707070;
  padding: 0px 1rem;
  ${media.phone} {
    letter-spacing: 0px;
  }
`;

const ReleaseBtnDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${media.phone} {
    width: 100%;
    margin: 0px 0.2rem;
    padding: 0.5rem 1rem;
  }

  ${RBtn} {
    ${media.phone} {
      display: none;
      width: 30%;
      margin: 0px 0.2rem;
      padding: 0.5rem 1rem;
    }
  }

  span {
    display: none;
    ${media.phone} {
      display: block;
      width: 60%;
    }
  }
`;
