export const thirdData = [
  {
    imgreal: "/images/thirdslider/baeglyeon/real/01_01.jpg",
    imgbim: "/images/thirdslider/baeglyeon/bim/01_01.png",
  },
  {
    imgreal: "/images/thirdslider/baeglyeon/real/01_02.jpg",
    imgbim: "/images/thirdslider/baeglyeon/bim/01_02.png",
  },
  {
    imgreal: "/images/thirdslider/baeglyeon/real/01_03.jpg",
    imgbim: "/images/thirdslider/baeglyeon/bim/01_03.png",
  },
  {
    imgreal: "/images/thirdslider/baeglyeon/real/01_04.jpg",
    imgbim: "/images/thirdslider/baeglyeon/bim/01_04.png",
  },
  {
    imgreal: "/images/thirdslider/sinchon/real/02_01.jpg",
    imgbim: "/images/thirdslider/sinchon/bim/02_01.png",
  },
  {
    imgreal: "/images/thirdslider/sinchon/real/02_02.jpg",
    imgbim: "/images/thirdslider/sinchon/bim/02_02.png",
  },
  {
    imgreal: "/images/thirdslider/sinchon/real/02_03.jpg",
    imgbim: "/images/thirdslider/sinchon/bim/02_03.png",
  },
  {
    imgreal: "/images/thirdslider/sinsa/real/03_01.jpg",
    imgbim: "/images/thirdslider/sinsa/bim/03_01.png",
  },
  {
    imgreal: "/images/thirdslider/sinsa/real/03_02.jpg",
    imgbim: "/images/thirdslider/sinsa/bim/03_02.png",
  },
  {
    imgreal: "/images/thirdslider/sinsa/real/03_03.jpg",
    imgbim: "/images/thirdslider/sinsa/bim/03_03.png",
  },
  {
    imgreal: "/images/thirdslider/ujeong/real/04_01.png",
    imgbim: "/images/thirdslider/ujeong/bim/04_01.png",
  },
  {
    imgreal: "/images/thirdslider/ujeong/real/04_02.png",
    imgbim: "/images/thirdslider/ujeong/bim/04_02.png",
  },
  {
    imgreal: "/images/thirdslider/junglim/real/05_01.jpg",
    imgbim: "/images/thirdslider/junglim/bim/05_01.png",
  },
  {
    imgreal: "/images/thirdslider/junglim/real/05_02.jpg",
    imgbim: "/images/thirdslider/junglim/bim/05_02.png",
  },
];

export const secondData = [
  {
    num: 1,
    img: "/images/secondslider/1.jpg",
  },
  {
    num: 2,
    img: "/images/secondslider/2.jpg",
  },
  {
    num: 3,
    img: "/images/secondslider/3.jpg",
  },
  {
    num: 4,
    img: "/images/secondslider/4.jpg",
  },
  {
    num: 5,
    img: "/images/secondslider/5.jpg",
  },
  {
    num: 6,
    img: "/images/secondslider/6.jpg",
  },
  {
    num: 7,
    img: "/images/secondslider/7.jpg",
  },
  {
    num: 8,
    img: "/images/secondslider/8.jpg",
  },
  {
    num: 9,
    img: "/images/secondslider/9.jpg",
  },
  {
    num: 10,
    img: "/images/secondslider/10.jpg",
  },
  {
    num: 11,
    img: "/images/secondslider/11.jpg",
  },
  {
    num: 12,
    img: "/images/secondslider/12.jpg",
  },
  {
    num: 13,
    img: "/images/secondslider/13.jpg",
  },
  {
    num: 14,
    img: "/images/secondslider/14.jpg",
  },
  {
    num: 15,
    img: "/images/secondslider/15.jpg",
  },
  {
    num: 17,
    img: "/images/secondslider/17.jpg",
  },
  {
    num: 18,
    img: "/images/secondslider/18.jpg",
  },
  {
    num: 19,
    img: "/images/secondslider/19.jpg",
  },
  {
    num: 20,
    img: "/images/secondslider/20.jpg",
  },

  {
    num: 21,
    img: "/images/secondslider/21.jpg",
  },

  {
    num: 22,
    img: "/images/secondslider/22.jpg",
  },

  {
    num: 23,
    img: "/images/secondslider/23.jpg",
  },

  {
    num: 24,
    img: "/images/secondslider/24.jpg",
  },

  {
    num: 25,
    img: "/images/secondslider/25.jpg",
  },

  {
    num: 26,
    img: "/images/secondslider/26.jpg",
  },

  {
    num: 27,
    img: "/images/secondslider/27.jpg",
  },

  {
    num: 28,
    img: "/images/secondslider/28.jpg",
  },

  {
    num: 29,
    img: "/images/secondslider/29.jpg",
  },

  {
    num: 30,
    img: "/images/secondslider/30.jpg",
  },

  {
    num: 31,
    img: "/images/secondslider/31.jpg",
  },
  {
    num: 32,
    img: "/images/secondslider/32.jpg",
  },
  {
    num: 33,
    img: "/images/secondslider/33.jpg",
  },
  {
    num: 33,
    img: "/images/secondslider/33.jpg",
  },
  {
    num: 34,
    img: "/images/secondslider/34.jpg",
  },
];
