import { SideSheet } from "evergreen-ui";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { BigSliderCount, isShow } from "../../libs/atoms";
import media from "../../libs/media";
import Navigation from "./HamNav";

export default function RootHeader({ children }) {
  const [click, setClick] = useRecoilState(BigSliderCount);
  const [isShown, setIsShown] = useRecoilState(isShow);
  return (
    <HeaderLayout>
      <HeaderDivison>
        <img src="/images/yugy_logo.png" width="60px" />
      </HeaderDivison>
      <HeaderDivisonList>
        <HeaderUl>
          <li onClick={() => setClick(0)}>MetaVerse</li>
          <li onClick={() => setClick(1)}>Green Remodeling</li>
          <li onClick={() => setClick(2)}>BIM</li>
          <li onClick={() => setClick(3)}>About us</li>
        </HeaderUl>
        <HamburgerBtn
          isShown={isShown}
          onClick={() => {
            setIsShown((prev) => !prev);
          }}
        />
        <SideSheet
          position={"right"}
          isShown={isShown}
          width={"100%"}
          shouldCloseOnEscapePress={true}
          shouldCloseOnOverlayClick={false}
          preventBodyScrolling={false}
        >
          <SideContainer>
            <CloseBtn
              onClick={() => {
                setIsShown((prev) => !prev);
              }}
            />
            <HamburgerNav>
              <Navigation />
            </HamburgerNav>
          </SideContainer>
        </SideSheet>
      </HeaderDivisonList>
      <SignUp onClick={() => console.log("click")}>
        <a href="https://build.yugy.org/">회원가입 | 로그인</a>
      </SignUp>
    </HeaderLayout>
  );
}

const SignUp = styled.div`
  display: flex;
  width: 45%;
  justify-content: flex-end;

  a {
    font: 24px/30px Arial;
    letter-spacing: 0px;
    color: white;
    padding: 0.5rem 1rem;
    background-color: #77b652;
    border-radius: 2rem;
  }

  ${media.phone} {
    display: none;
  }
`;

const HeaderLayout = styled.div`
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 1rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #727070;
    padding: 1rem;
  }

  ${media.phone} {
    background-color: #e8ebeb;
    justify-content: space-between;
    padding: 0px 0.2rem;
  }
`;

const HeaderDivison = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
`;
const HeaderDivisonList = styled(HeaderDivison)`
  width: 50%;
`;

const HeaderUl = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 1.2rem;
  margin-left: 2rem;
  font: normal normal bold 30px/34px Arial;
  letter-spacing: 0px;
  color: #707070;

  li {
    font-size: 1.5rem;
    font-weight: 600;
    color: #727070;
    cursor: pointer;
  }

  ${media.phone} {
    li {
      display: none;
    }
  }
`;

const HamburgerBtn = styled.div`
  display: none;
  min-width: 42px;
  min-height: 28px;
  font-size: 2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/images/header/nav-hamburger.svg");
  ${media.phone} {
    display: block;
    cursor: pointer;
  }
`;

const HamburgerNav = styled.ul`
  max-width: 1920px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  li {
    margin: 30px auto;
  }
`;

const SideContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #e8ebeb;
`;

const CloseBtn = styled.div`
  width: 50px;
  height: 50px;
  align-self: flex-end;
  margin: 0 0 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/images/header/nav-close.svg");
`;
