import { atom } from "recoil";

export const DarkThemeState = atom({
  key: "DarkTheme",
  default: true,
});

export const LangState = atom({
  key: "language",
  default: true,
});
export const PubKey = atom({
  key: "PubKey",
  default: "",
});
export const isFull = atom({
  key: "Full",
  default: false,
});
export const FullNumber = atom({
  key: "FullNum",
  default: 0,
});
export const SecondPlay = atom({
  key: "SecondPlay",
  default: true,
});
export const PrevSecondPlay = atom({
  key: "PrevSecondPlay",
  default: true,
});
export const BigSliderCount = atom({
  key: "PrevSecondPlay",
  default: 0,
});
export const isShow = atom({
  key: "show",
  default: false,
});
