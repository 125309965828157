import styled from "styled-components";
import media from "../libs/media";

export const WW2 = styled.div`
  margin-top: 2rem;
`;

export const WW = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Back2 = styled.div`
  width: 50%;
  height: 40%;
  border-radius: 2rem;
  background: white;

  ${media.custom(1600)} {
    width: 70%;
  }

  ${media.custom(1400)} {
    width: 80%;
  }

  ${media.custom(1200)} {
    width: 95%;
  }

  ${media.custom(1000)} {
    height: 100%;
  }
`;

export const MainMintButton = styled.div`
  width: 377px;
  height: 72px;
  border-radius: 1rem;
  background: #f0f0f0;
  border: 1px solid black;
  cursor: pointer;
`;

export const Con = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const T1 = styled.div`
  margin-top: 3rem;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 3rem;
`;

export const Text = styled.div`
  width: 559px;
  height: 76px;
  padding-bottom: 20px;
  font-size: 40px;

  ${media.custom(1000)} {
    width: 559px;
    height: 76px;
    padding-bottom: 20px;
    border: 1px solid red;
    display: flex;
    justify-content: center;
  }
`;

const EmailBtn = styled.div`
  width: 350px;
  height: 45px;
  background-image: url("images/email_btn.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 1rem;
  cursor: pointer;
`;

export const EmailButton = () => (
  <>
    <EmailBtn></EmailBtn>
  </>
);
