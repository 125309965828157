import styled from "styled-components";
import { GlobalStyles } from "./styles";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <RecoilRoot>
      <RouterProvider router={router} />
      <GlobalStyles />
    </RecoilRoot>
  );
}

export default App;
