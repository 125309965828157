import styled from "styled-components";
import { InfoLay } from "../components/Enter/InfoLay";
import { Release } from "../components/Enter/Release";

export default function Enter() {
  return (
    <EnterCenter>
      <InfoLay />
      <Release />
    </EnterCenter>
  );
}

const EnterCenter = styled.div`
  width: 100%;
  min-height: 90vh;
  display: flex;
  text-align: center;
  flex-direction: column;
`;
