import React from "react";
import styled from "styled-components";
import media from "../../libs/media";
import { EmailButton } from "../Common";

export function ThirdSlider() {
  const goToNotion = () => {
    window.open(
      `https://daiblab.notion.site/HERE-NOW-abf870603b9c4b80802b4da7f7aa8220`
    );
  };

  return (
    <FirstSliderLayout>
      <FirstLayout>
        <IFrame
          src={`https://www.youtube.com/embed/U4OAqHCUcnM?si=VdPcVHgcBr0eHlPw?controls=0?light=1`}
          allow="accelerometer; picture-in-picture"
        ></IFrame>
        <DescSection>
          <h1>BIM</h1>

          <span>
            BIM(Building Information Modelling)은 건축, 일반 공사, 시설 관리
            등의 산업에서 사용되는 컴퓨터 기반 기술로 건축물의 설계 시나리오를
            미리 시뮬레이션하여 시행착오를 최소화하며, 공사기간을 단축하고
            비용을 절감 할 수 있고, 시공 과정에서 실시간 현장 상황을 파악할 수
            있어 시공 품질이 향상됩니다.
          </span>
        </DescSection>
      </FirstLayout>
    </FirstSliderLayout>
  );
}

const MetaBtn = styled.div`
  width: 250px;
  height: 107px;
  cursor: pointer;

  background-image: url("images/meta_btn.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0.5rem 0.6rem;

  margin-top: 1rem;
`;

const SliderLogo = styled.img`
  margin: 1rem 0px;
`;

const DescSection = styled.div`
  color: #707070;
  width: 30%;

  height: 80%;
  padding: 4rem;
  font: normal normal normal 16px/24px Nanum Gothic;
  background-color: #bebebe;
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;

  h1 {
    padding: 1rem 0px;
    font-size: 30px;
    font-weight: 700;
  }
  span {
    display: flex;
    min-width: 300px;
  }
  ${media.phone} {
    padding: 0px;
    width: auto;
    span {
      margin-bottom: 1rem;
    }
  }
`;

const SliderLayout = styled.div`
  width: 90% !important;
  height: 100%;
  min-height: 60vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FirstSliderLayout = styled(SliderLayout)`
  max-width: 1600px;
`;

const FirstLayout = styled.div`
  margin-top: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.phone} {
    flex-direction: column;
    margin-top: 1rem;
  }
`;

const IFrame = styled.iframe`
  width: 60%;
  min-height: 500px;

  .ytp-ytp-gradient-top {
    display: none;
  }

  ${media.phone} {
    width: 100%;
    min-height: 200px;
  }
`;
