import { useRecoilState } from "recoil";
import styled from "styled-components";
import { BigSliderCount, isShow } from "../../libs/atoms";

function Navigation() {
  const [isShown, setIsShown] = useRecoilState(isShow);
  const [click, setClick] = useRecoilState(BigSliderCount);

  return (
    <HamNavList>
      <li
        onClick={() => {
          setClick(0);
          setIsShown(false);
        }}
      >
        MetaVerse
      </li>
      <li
        onClick={() => {
          setClick(1);
          setIsShown(false);
        }}
      >
        Green Remodeling
      </li>
      <li
        onClick={() => {
          setClick(2);
          setIsShown(false);
        }}
      >
        BIM
      </li>
      <li
        onClick={() => {
          setClick(3);
          setIsShown(false);
        }}
      >
        About us
      </li>
    </HamNavList>
  );
}

const HamNavList = styled.div`
  font: normal normal bold 30px/34px Arial;
  letter-spacing: 0px;
  color: #707070;
`;

export default Navigation;
