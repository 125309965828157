import styled from "styled-components";
import media from "../libs/media";

export const LeftArrow = styled.div`
  z-index: 100;
  background: no-repeat url("/images/slider_arrow_left.png");
  background-size: contain !important;
  width: 50px;
  height: 103px;
  ${media.small} {
    display: none;
  }

  :hover {
    background: no-repeat url("/images/slider_arrow_left.png");
  }

  ${media.phone} {
    display: none !important;
  }
`;
export const RightArrow = styled(LeftArrow)`
  background: no-repeat url("/images/slider_arrow_right.png");
  :hover {
    background: no-repeat url("/images/slider_arrow_right.png");
  }

  ${media.phone} {
    display: none !important;
  }
`;
