import React from "react";
import Slider from "react-slick";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { BigSliderCount } from "../../libs/atoms";
import media from "../../libs/media";
import { LeftArrow, RightArrow } from "../Slider";
import { FirstSlider } from "../Slider/FirstSlider";
import { FourSlider } from "../Slider/FourthSlider";
import { SecondSlider } from "../Slider/SecondSlider";
import { ThirdSlider } from "../Slider/ThirdSlider";

export function InfoLay() {
  const [click, setClick] = useRecoilState(BigSliderCount);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
    beforeChange: (current, next) => {
      setClick(next);
    },
  };

  return (
    <InfoSection>
      <InfoSlider
        {...settings}
        ref={(slider) => {
          slider?.slickGoTo(click);
        }}
      >
        <FirstSlider />
        <SecondSlider />
        <ThirdSlider />
        <FourSlider />
      </InfoSlider>
    </InfoSection>
  );
}

const InfoSection = styled.div`
  width: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #707070;
  background-color: #bebebe;
  font-weight: 400;
  strong {
    font-weight: 700;
  }
`;

const InfoSlider = styled(Slider)`
  display: flex;
  align-items: center;
  justify-content: center;
  .slick-next {
    right: 1rem;
    :before {
      display: none;
    }
  }
  .slick-prev {
    left: 1rem;
    :before {
      display: none;
    }
    :hover {
      display: none;
    }
  }
`;
