import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { secondData } from "../../libs/data";
import { FiPlay } from "react-icons/fi";
import { BiFullscreen } from "react-icons/bi";
import { GiPauseButton } from "react-icons/gi";
import {
  FullNumber,
  isFull,
  PrevSecondPlay,
  SecondPlay,
} from "../../libs/atoms";
import { useRecoilState } from "recoil";
import media from "../../libs/media";
import { EmailButton } from "../Common";

export function SecondSlider() {
  const [full, setFull] = useRecoilState(isFull);
  const [fullNum, setFullNum] = useRecoilState(FullNumber);
  const [onPlay, setPlay] = useRecoilState(SecondPlay);
  const [onPrevPlay, setPrevPlay] = useRecoilState(PrevSecondPlay);
  const [click, setClick] = useState(0);
  const [secondMainSlide, setSMainSlide] = useState();
  const [secondSubSlide, setSSubSlide] = useState();
  const clickPlay = () => {
    setPlay((p) => !p);
  };
  const onFull = () => {
    setFullNum(click);
    setPrevPlay(onPlay);
    setPlay(false);
    setFull((p) => !p);
  };

  const SubClick = (data) => {
    setClick(data);
  };
  useEffect(() => {
    onPlay ? secondMainSlide?.slickPlay() : secondMainSlide?.slickPause();
  }, [onPlay, setPlay, SecondPlay, secondMainSlide]);

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };
  const settings3 = {
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => {
      setClick(next);
    },
  };
  return (
    <SecondSliderLayout>
      <SecondLayout>
        <SecondSliderSectionUp>
          <SliderSectionDiv>
            <SecondSliders
              asNavFor={secondSubSlide}
              ref={(slider) => {
                slider?.slickGoTo(click);
                return setSMainSlide(slider);
              }}
              {...settings2}
            >
              {secondData.map((props, i) => (
                <SSSULayout key={`sssu${i}`}>
                  <SSSUFLEX>
                    <SSSUImg bg={props.img} />
                  </SSSUFLEX>
                </SSSULayout>
              ))}
            </SecondSliders>
            <SliderBtnSection>
              {!onPlay ? (
                <FiPlay onClick={clickPlay} />
              ) : (
                <GiPauseButton onClick={clickPlay} />
              )}
              <BiFullscreen onClick={onFull} />
            </SliderBtnSection>
            <SecondSliders
              asNavFor={secondMainSlide}
              ref={(slider) => setSSubSlide(slider)}
              {...settings3}
            >
              {secondData.map((props, i) => (
                <SSSUSubLayout key={`sssu${i}`}>
                  <SSSUFLEX>
                    <SSUSubImg
                      onClick={() => SubClick(i)}
                      num={click}
                      i={i}
                      bg={props.img}
                    />
                  </SSSUFLEX>
                </SSSUSubLayout>
              ))}
            </SecondSliders>
          </SliderSectionDiv>
        </SecondSliderSectionUp>
        <SecondSliderSectionDown>
          <h1>Green Remodeling</h1>
          <span>
            "그린리모델링” 이란 에너지 성능 향상 및 효율 개선 등을 통하여 기존
            건축물을 녹색건축물로 전환하는 활동입니다. 정부는 공공 및
            민간건축물의 건축주 또는 그린리모델링 사업자가 그린리모델링공사를
            위해 은행에서 대출받은 공사비(단독주택 1억, 공동주택 3천만원,
            비주거건축물 최대 50억)의 이자 전부 또는 일부(3%)를 보조하는
            그린리모델링 이자지원사업을 시행하고 있습니다. 건축물에 사용되는
            에너지 소비량과 온실가스 배출량을 줄이고 동시에 쾌적하고 건강한
            거주환경을 제공하기 위함입니다."
          </span>
          <IFrame
            src={`https://www.youtube.com/embed/SLiBcJw9pOc?si=MgIfZuTeCF8DyHLn?controls=0?light=1`}
            allow="accelerometer; picture-in-picture"
          ></IFrame>
          <EmailButton />
        </SecondSliderSectionDown>
      </SecondLayout>
    </SecondSliderLayout>
  );
}

const Ul = styled.ul`
  list-style: disc;
  font-size: 1.2rem;
  padding: 1rem;

  li {
    margin-bottom: 1rem;
  }
`;

const EmailBtn = styled.div`
  width: 50%;
  text-align: center;
  padding: 0.5rem 0.6rem;
  border-radius: 1rem;
  color: white;
  background-color: #86b2c6;
  ${media.small} {
    width: 70%;
  }
`;

const SliderSectionDiv = styled.div`
  position: relative;
  margin-top: 4%;
`;

const SliderBtnSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  padding: 1rem;
  color: white;
  position: absolute;
  bottom: 15%;
`;

const SecondLayout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.phone} {
    flex-direction: column;
  }
`;

const SecondSliderSectionUp = styled.div`
  width: 60%;
  height: 60%;
  margin-bottom: 1rem;
  h1 {
    font: normal normal bold 30px/34px Arial;
    letter-spacing: 0px;
    color: #707070;
    text-align: left;
    padding: 1rem 0px;
  }
  ${media.phone} {
    width: 100%;
    height: auto;
  }
`;

const SSSULayout = styled.div`
  width: 80%;
  height: 50vh;
  background-color: black;
  ${media.phone} {
    height: 30vh;
  }
`;

const SSSUSubLayout = styled.div`
  height: auto;
`;

const SSSUFLEX = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
`;

const SSSUImg = styled.div`
  width: 70%;
  height: 100%;
  min-height: 500px;
  background-color: black;
  background-image: ${(props) => `url(${props.bg})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  ${media.phone} {
    min-height: 200px;
  }
`;

const SSUSubImg = styled(SSSUImg)`
  width: 90%;
  min-height: 50px;
  margin-bottom: 0.5rem;
  border: ${(props) => (props.i === props.num ? "1px solid #FFE400" : "none")};
`;

const SecondSliderSectionDown = styled.div`
  text-align: left;
  padding: 4rem;
  width: 40%;
  font: normal normal normal 16px/24px Nanum Gothic;
  margin-bottom: 1rem;
  h1 {
    font-weight: 700;
    padding: 1rem 0.2rem;
    font-size: 30px;
    ${media.phone} {
      font-size: 24px;
    }
  }

  ${media.phone} {
    width: 100%;
    padding: 0px;
  }
`;

const SecondSliders = styled(Slider)`
  padding: 0px;
  margin: 0px;
  .slick-dots {
    position: absolute;
    bottom: 5%;
  }
  .slick-dots li button:before {
    color: white;
    border-radius: 50%;
  }
`;

const SliderLayout = styled.div`
  width: 90% !important;
  height: 100%;
  min-height: 40vh;
  margin: 0 auto;
`;
const SecondSliderLayout = styled(SliderLayout)`
  max-width: 1600px;
  position: relative;
`;

const IFrame = styled.iframe`
  width: 100%;
  margin-top: 1rem;
  min-height: 300px;

  .ytp-ytp-gradient-top {
    display: none;
  }

  ${media.phone} {
    width: 100%;
    min-height: 200px;
  }
`;
